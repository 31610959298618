<template>
  <div class="wrap">
    <NavBar
      :fixed="true"
      :title="$t('login.userAgreement')"
      left-arrow
      placeholder
      @click-left="$router.go(-1)"
    />
    <div class="content" v-html="content"></div>
  </div>
</template>

<script>
import { GetUserAgreement } from "../../api";
import { NavBar } from "vant";
export default {
  components: { NavBar },
  data: () => ({
    content: ""
  }),
  created() {
    this.getContent();
  },
  methods: {
    async getContent() {
      const {
        data: { ret, msg, data },
      } = await GetUserAgreement();
      if(ret == 1 && !!data && "agreement" in data){
        this.content = data.agreement;
      }
    },
  },
};
</script>

<style lang="less" scoped>
.content{
  padding: calc(10rem / 16);
  color: var(--sizeColor);
}
</style>